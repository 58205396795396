import { createAsyncThunk } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  clearNotifs,
  getMarkers,
  getNotificationMessage,
  getEditProfileMessage,
  logOut,
  setAdminNotifRequest,
  setIsNotifViewing,
  setIsProfileChanged,
  setUserSectionPage,
  setMarkerUpdate,
  setPingStatus,
  setResTime1,
  setResTime2,
  setResTime3,
  setResTime4,
  removeMarker
} from 'store'
import { liveMarkerUpdate, staticMarkerUpdate } from 'store/markers/axios'
// eslint-disable-next-line import/no-cycle
import { usersApi } from 'store/users/api'
import {
  MY_PROFILES,
  REJECTED,
  SUCCEEDED
} from 'utils/constants'
import {
  getParsedData,
  removeStorage,
  checkForceLogout,
  isAuth
} from 'utils/helpers'
import { LogoutFromChat } from 'store/chat/services'

export const forceLogout = createAsyncThunk(
  'forceLogout',
  async (errMsg, { rejectWithValue, dispatch }) => {
    try {
      if (checkForceLogout(errMsg)) {
        dispatch(logOut(false))

        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: errMsg,
            isEditProfile: true
          })
        )

        return true
      }

      return false
    } catch (err) {
      return rejectWithValue(err.message)
    }
  }
)

export const getUserProfile = createAsyncThunk(
  'users/profile',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let status = ''
      let stringCoordCount = 0
      const request_start = Date.now()
      const response = await usersApi.getUserProfiles()
      if (response.status === 200) {
        status = 'OK'
      } else {
        status = 'FAIL'
      }
      response.data.data.forEach((item) => {
        if (item.coordinates !== null) {
          if (typeof item.coordinates[0] === 'string' || typeof item.coordinates[1] === 'string') {
            // item.coordinates[0] = Number(parseFloat(item.coordinates[0]))
            // item.coordinates[1] = Number(parseFloat(item.coordinates[1]))
            stringCoordCount += 1
          }
        }
      })

      if (stringCoordCount > 0) {
        dispatch(getNotificationMessage({
          type: REJECTED,
          message: 'One of your profile coordinates value has wrong format.',
          isEditProfile: false
        }))
      }
      const request_end = Date.now()

      const duration = request_end - request_start
      await dispatch(setResTime3({ duration, status }))

      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Get Users Profile Error',
          isEditProfile: true
        })
      )
      dispatch(setResTime3({ duration: null, status: 'FAIL' }))
      return rejectWithValue(error.message)
    }
  }
)

export const getUser = createAsyncThunk('users', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await usersApi.getUser()

    dispatch(getUserProfile())

    localStorage.setItem('lat', response.data.data.lat)
    localStorage.setItem('lng', response.data.data.lng)

    return response.data
  } catch (error) {
    const doForceLogout = await dispatch(forceLogout(error.data.message))
    if (doForceLogout.payload === true) {
      return rejectWithValue(error.message)
    }

    removeStorage('credentials')
    if (error.status === 403) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Email Address is not verified',
          isEditProfile: true
        })
      )
    }

    return rejectWithValue(error.message)
  }
})

export const deleteUser = createAsyncThunk(
  'delete/user',
  async (activeProfile, { rejectWithValue, dispatch }) => {
    const notifyUiAfterProfileRemovalNotification = () => new Promise((resolve) => {
      window.addEventListener('custom-chat-profile-removed-notified', resolve, { once: true })
    })
    try {
      if (activeProfile !== undefined) {
        // Logout from XMPP before deleting the account to avoid XMPP wrong credentials error
        const event = new CustomEvent('custom-chat-profile-removed')
        window.dispatchEvent(event)
        await notifyUiAfterProfileRemovalNotification()
        await dispatch(LogoutFromChat())
      }

      const response = await usersApi.deleteUser()
      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Account Deleted',
          isEditProfile: true
        })
      )
      dispatch(logOut(false))
      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Delete user error',
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const getAffinities = createAsyncThunk(
  'search/affinity',
  async (body, { rejectWithValue }) => {
    try {
      const response = await usersApi.searchAffinities(body)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateSwitcher = createAsyncThunk(
  'update/Switcher',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.updateSwitcher(id)
      await dispatch(setMarkerUpdate(true))

      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getAdminNotifications = createAsyncThunk(
  'get/AdminNotifications',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const request_start = performance.now()
      const response = await usersApi.getAdminNotifications()
      const request_end = performance.now()
      // eslint-disable-next-line no-unused-vars
      const request_duration = request_end - request_start
      await dispatch(setAdminNotifRequest(request_end))
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getFriendsNotifications = createAsyncThunk(
  'get/notifications',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getFriendsNotifications()
      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const selectProfile = createAsyncThunk(
  'select/profile',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.selectProfile(body.profileId)
      await dispatch(getUserProfile())

      if (body.showNotifMsg) {
        await dispatch(getNotificationMessage({
          type: SUCCEEDED,
          message: 'User selected',
          isEditProfile: true
        }))
      }

      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Failed to select user',
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const updateUserProfile = createAsyncThunk(
  'profile/update',
  async (body, { rejectWithValue, dispatch }) => {
    const {
      userData, id, formData, formData2, switcherData
    } = body

    userData.coordinates = [
      Number(parseFloat(userData.coordinates[0]).toFixed(6)),
      Number(parseFloat(userData.coordinates[1]).toFixed(6))
    ]

    try {
      let result = {}

      if (formData && formData2) {
        result = await Promise.all([
          usersApi.updateProfile(userData),
          usersApi.updateProfileImage(formData, id),
          usersApi.updateBanner(formData2, id)
        ])
      } else if (!formData && formData2) {
        result = await Promise.all([
          usersApi.updateProfile(userData),
          usersApi.updateBanner(formData2, id)
        ])
      } else if (formData && !formData2) {
        result = await Promise.all([
          usersApi.updateProfile(userData),
          usersApi.updateProfileImage(formData, id)
        ])
      } else {
        result = await Promise.all([usersApi.updateProfile(userData)])
      }

      await dispatch(setIsProfileChanged(false))
      await dispatch(selectProfile({
        profileId: id,
        showNotifMsg: false
      }))
      await dispatch(getUserProfile())
      await dispatch(updateSwitcher(switcherData))

      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Saved',
          isEditProfile: true
        })
      )

      const event = new CustomEvent('custom-chat-avatar_change', {
        detail: {
          full_name: userData.full_name
        }
      })
      window.dispatchEvent(event)

      return result.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      if (error.status === 422) {
        let errorMessage = ''
        let SocialLink = []
        let CodeWord = []
        let data = []
        let data2 = []
        let data3 = []
        if (error.data.errors.profile_picture) {
          data = error.data.errors.profile_picture
          data.forEach((item) => { errorMessage += ' ' + item + '\n' })
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: errorMessage,
              isEditProfile: true
            })
          )
        }
        if (error.data.errors.code_words || error.data.errors.socialLinks) {
          data2 = error.data.errors.code_words
          data3 = error.data.errors.socialLinks
          if (data2 && !data3) {
            data2.forEach((item) => {
              if (item) {
                CodeWord.push(item?.match(/"(.*?)"/)?.[1])
              }
            })
          } else if (!data2 && data3) {
            data3.forEach((item) => {
              if (item) {
                SocialLink.push(item.replace('Invalid URL in ', ''))
              }
            })
          } else {
            data2.forEach((item) => {
              if (item) {
                CodeWord.push(item?.match(/"(.*?)"/)?.[1])
              }
            })
            data3.forEach((item) => {
              if (item) {
                SocialLink.push(item.replace('Invalid URL in ', ''))
              }
            })
          }
          dispatch(
            getEditProfileMessage({
              message1: CodeWord,
              message2: SocialLink
            })
          )
        } else {
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: error.data.message,
              isEditProfile: true
            })
          )
        }
      } else {
        // If an xmpp error, there should be a long, confusing message
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Oops, something went wrong with the server. Please try again later',
            isEditProfile: true
          })
        )
      }

      return rejectWithValue(error.message)
    }
  }
)

export const deleteUserProfile = createAsyncThunk(
  'delete/profile',
  async (id, { rejectWithValue, dispatch, getState }) => {
    const notifyUiAfterProfileRemovalNotification = () => new Promise((resolve) => {
      window.addEventListener('custom-chat-profile-removed-notified', resolve, { once: true })
    })
    try {
      const { users } = getState()
      if (users.activeProfile.id === id) {
        // Logout from XMPP before deleting a profile to avoid XMPP wrong credentials error
        // More details can be seen on task #664
        const event = new CustomEvent('custom-chat-profile-removed')
        window.dispatchEvent(event)
        await notifyUiAfterProfileRemovalNotification()
        await dispatch(LogoutFromChat())
        const response = await usersApi.deleteProfile(id)
        await dispatch(
          getNotificationMessage({
            type: SUCCEEDED,
            message: 'Profile has been deleted',
            isEditProfile: true
          })
        )
        await dispatch(clearNotifs())
        await dispatch(setUserSectionPage(MY_PROFILES))
        await dispatch(getUserProfile())
        await dispatch(getMarkers({ isAuth: true }))
        return response.data.data
      }
      // this is not active profile deleted, not needed to exit from chat and switch profile
      const response = await usersApi.deleteProfile(id)
      await dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Profile has been deleted',
          isEditProfile: true
        })
      )
      await dispatch(setUserSectionPage(MY_PROFILES))
      await dispatch(getUserProfile())
      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Failed to delete profile',
          isEditProfile: true
        })
      )
      return rejectWithValue(error.message)
    }
  }
)

export const getSearchParams = createAsyncThunk(
  'search/params',
  async (body, { rejectWithValue }) => {
    try {
      const response = await usersApi.getSearchParams(body)

      response.data.code_words = getParsedData(response.data.code_words)

      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateUserSettings = createAsyncThunk(
  'user/setting',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.updateSettings(data.body)

      if (data.type === 'email') {
        dispatch(
          getNotificationMessage({
            type: SUCCEEDED,
            message: 'If that ID is not yet registered, you should receive an email soon',
            isEditProfile: true
          })
        )
      } else {
        dispatch(
          getNotificationMessage({
            type: SUCCEEDED,
            message: 'Saved',
            isEditProfile: true
          })
        )
      }

      dispatch(getUser())
      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      if (error.status === 422) {
        const errorType = Object.values(error.data)[1]

        if (Object.keys(errorType)[0] === 'email') {
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: 'The email has already been taken.',
              isEditProfile: true
            })
          )
        }
        if (Object.keys(errorType)[0] === 'password_current') {
          dispatch(
            getNotificationMessage({
              type: REJECTED,
              message: 'Current password is incorrect',
              isEditProfile: true
            })
          )
        }
      } else {
        dispatch(
          getNotificationMessage({
            type: REJECTED,
            message: 'Oops, something went wrong with the server. Please try again later',
            isEditProfile: true
          })
        )
      }

      return rejectWithValue(error.message)
    }
  }
)

export const addFriend = createAsyncThunk('add/friend', async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await usersApi.addFriend(id)

    dispatch(
      getNotificationMessage({
        type: SUCCEEDED,
        message: 'Friend request sent'
      })
    )

    return response.data.data
  } catch (error) {
    const doForceLogout = await dispatch(forceLogout(error.data.message))
    if (doForceLogout.payload === true) {
      return rejectWithValue(error.message)
    }

    if (error.status === 422) {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message
        })
      )
    }

    return rejectWithValue(error.message)
  }
})

export const deleteFriend = createAsyncThunk(
  'delete/friend',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.deleteFriend(id)
      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getBlockedRequest = createAsyncThunk(
  'get/blockedRequest',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getBlockedRequest(id)
      // dispatch(getFriendsNotifications())

      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))
      return rejectWithValue(error.message)
    }
  }
)

export const acceptFriend = createAsyncThunk(
  'add/acceptFriend',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.acceptFriend(body?.id)
      dispatch(getBlockedRequest(body?.activeId))
      dispatch(getFriendsNotifications())
      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const denyFriend = createAsyncThunk(
  'delete/denyFriend',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.denyFriend(body?.id)
      dispatch(getFriendsNotifications())
      dispatch(getBlockedRequest(body?.activeId))
      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getProfileItem = createAsyncThunk(
  'delete/getProfileItem',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getProfileItem(id)

      return response.data.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      if (error.data.message === 'Profile doesn\'t exist') {
        dispatch(removeMarker(id))

        const northeast = JSON.parse(localStorage.getItem('northeast'))
        const southwest = JSON.parse(localStorage.getItem('southwest'))
        dispatch(staticMarkerUpdate({
          isAuth: isAuth(),
          northeast,
          southwest
        }))

        dispatch(liveMarkerUpdate({
          isAuth: isAuth(),
          northeast,
          southwest
        }))
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const postMakeNotificationSeen = createAsyncThunk(
  'post/MakeNotificationSeen',
  // eslint-disable-next-line consistent-return
  async (id, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setIsNotifViewing(true))
      const response = await usersApi.postMakeNotificationSeen(id)
      dispatch(getAdminNotifications()).then(() => {
        localStorage.setItem('openedNotifId', id)
        dispatch(setIsNotifViewing(false))
      })

      return response.data.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getSocialLinksCategories = createAsyncThunk(
  'get/socialLinksCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersApi.getSocialLinksCategories()
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getSocialLinks = createAsyncThunk(
  'search/socialLinks',
  async (body, { rejectWithValue }) => {
    try {
      const response = await usersApi.searchSocialLinks(body)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getFrequency = createAsyncThunk(
  'get/frequency',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersApi.getFrequency()
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const setLiveLocData = createAsyncThunk(
  'get/LiveLocData',
  async (data, { rejectWithValue, dispatch }) => {
    const { id, coordinates } = data
    try {
      const response = await usersApi.setLiveLocData(id, coordinates)
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))

      return rejectWithValue(error.message)
    }
  }
)

export const getPingTest = createAsyncThunk(
  'get/pingTest',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getPingTest()
      const request_start = Date.now()
      if (response.status === 204) {
        dispatch(setPingStatus('OK'))
      } else {
        dispatch(setPingStatus('FAIL'))
      }
      const request_end = Date.now()
      const duration = request_end - request_start
      let serverName = process.env.REACT_APP_API_URL
      if (serverName.includes('127.0.0.1')) {
        serverName = 'localhost'
      } else {
        // eslint-disable-next-line prefer-destructuring
        serverName = serverName.split('://')[1]
        // eslint-disable-next-line prefer-destructuring
        serverName = serverName.split('/api')[0]
      }
      await dispatch(setResTime1({ duration, serverName }))
      return response.data
    } catch (error) {
      let serverName = process.env.REACT_APP_API_URL
      if (serverName.includes('127.0.0.1')) {
        serverName = 'localhost'
      } else {
        // eslint-disable-next-line prefer-destructuring
        serverName = serverName.split('://')[1]
        // eslint-disable-next-line prefer-destructuring
        serverName = serverName.split('/api')[0]
      }

      dispatch(setPingStatus('FAIL'))
      dispatch(setResTime1({ duration: null, serverName }))
      return rejectWithValue(error.message)
    }
  }
)

export const getFileTest = createAsyncThunk(
  'get/itemTest',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let status = ''
      const request_start = Date.now()
      const response = await usersApi.getFileTest()
      if (response.status === 200) {
        status = 'OK'
      } else {
        status = 'FAIL'
      }
      const request_end = Date.now()
      const duration = request_end - request_start
      await dispatch(setResTime2({ duration, status }))
      return response.data
    } catch (error) {
      dispatch(setResTime2({ duration: null, status: 'FAIL' }))
      return rejectWithValue(error.message)
    }
  }
)

export const setDataTest = createAsyncThunk(
  'set/dataTest',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      let status = ''
      const request_start = Date.now()
      const response = await usersApi.setDataTest(data)
      if (response.status === 200) {
        status = 'OK'
      } else {
        status = 'FAIL'
      }
      const request_end = Date.now()
      const duration = request_end - request_start
      await dispatch(setResTime4({ duration, status }))
      return response.data
    } catch (error) {
      dispatch(setResTime4({ duration: null, status: 'FAIL' }))
      return rejectWithValue(error.message)
    }
  }
)

export const archiveRequest = createAsyncThunk(
  'set/archiveRequest',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.archiveRequest(body.id)
      dispatch(getFriendsNotifications())
      dispatch(getBlockedRequest(body.activeId))

      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))
      return rejectWithValue(error.message)
    }
  }
)

export const getPosts = createAsyncThunk(
  'get/posts',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getPosts(id)

      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))
      return rejectWithValue(error.message)
    }
  }
)

export const postPosts = createAsyncThunk(
  'post/posts',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.postPosts(body)
      dispatch(getPosts({
        type: 'profile',
        id: body.id
      }))

      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Post created',
          isEditProfile: true
        })
      )

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const putPosts = createAsyncThunk(
  'put/posts',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.putPosts(body)
      dispatch(getPosts({
        type: 'profile',
        id: body.id
      }))

      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Post updated',
          isEditProfile: true
        })
      )

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const likePost = createAsyncThunk(
  'post/like',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.likePost({ id })

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const getBlogPreference = createAsyncThunk(
  'get/blog-preference',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getBlogPreference()

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const blogPreferenceAllContacts = createAsyncThunk(
  'get/blog-preference/all-contacts',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.blogPreferenceAllContacts()
      await dispatch(getBlogPreference())

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const addBlogPreference = createAsyncThunk(
  'set/blog-preference',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.addBlogPreference({ id })
      await dispatch(getBlogPreference())

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const removeBlogPreference = createAsyncThunk(
  'delete/blog-preference',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.removeBlogPreference({ id })
      await dispatch(getBlogPreference())

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)

export const getComments = createAsyncThunk(
  'get/comments',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.getComments(id)

      return response.data
    } catch (error) {
      console.log(error)
      await dispatch(forceLogout(error.data.message))
      return rejectWithValue(error.message)
    }
  }
)

export const postComments = createAsyncThunk(
  'post/comments',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.postComments(body)

      let data = {}
      if (body.type === 'post') {
        data = {
          type: body.type,
          post_id: body.post_id
        }
      } else if (body.type === 'comment') {
        data = {
          type: body.type,
          comment_id: body.comment_id
        }
      }
      dispatch(getComments(data))

      dispatch(
        getNotificationMessage({
          type: SUCCEEDED,
          message: 'Comment created',
          isEditProfile: true
        })
      )
      return response.data
    } catch (error) {
      await dispatch(forceLogout(error.data.message))
      return rejectWithValue(error.message)
    }
  }
)

export const likeComment = createAsyncThunk(
  'post/commment',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersApi.likeComment({ id })

      return response.data
    } catch (error) {
      const doForceLogout = await dispatch(forceLogout(error.data.message))
      if (doForceLogout.payload === true) {
        return rejectWithValue(error.message)
      }

      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: error.data.message,
          isEditProfile: true
        })
      )

      return rejectWithValue(error.message)
    }
  }
)
