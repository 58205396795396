import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import { likePost } from 'store'
import {
  EditIcon2,
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import { PENDING } from 'utils/constants'

const formattedDate = (origDate) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const date = new Date(origDate)

  return date.toLocaleDateString('en-US', options)
}

export const BlogList = ({
  profilePicture,
  fullName,
  posts,
  setEditPostData,
  getPostsStatus
}) => {
  const dispatch = useDispatch()

  if (getPostsStatus === PENDING && !posts.length) {
    return (
      <Stack marginTop="100px">
        <LoadingSpinier />
      </Stack>
    )
  }

  const likeThisPost = (id) => {
    dispatch(likePost(id))
  }

  return (
    <Stack marginTop="20px">
      {
        posts && posts.length
          ? posts.map((item) => {
            const [hideText, setHideText] = useState(0)

            return (
              <Stack
                backgroundColor="rgb(229, 229, 229)"
                width="calc(100% + 112px)"
                borderRadius="3px"
                border="solid 1px black"
                padding="10px"
                display="flex"
                justifyContent="flex-start"
                marginLeft="-56px"
              >
                <Stack
                  width="15px"
                  height="15px"
                  marginLeft="auto"
                  cursor="pointer"
                  onClick={() => {
                    setEditPostData({})
                    setEditPostData(item)
                  }}
                >
                  <EditIcon2 />
                </Stack>

                <Stack
                  alignItems="center"
                  display="inline"
                  width="100%"
                  justifyContent="flex-start"
                  marginLeft="30px"
                >
                  <Image
                    boxSize="55px"
                    position="relative"
                    objectFit="cover"
                    src={profilePicture || ImagesPng.DefaultImage}
                    alt="User Image"
                    borderRadius="full"
                    display="inline-flex"
                    verticalAlign="bottom"
                  />
                  <Text
                    display="inline-flex"
                    marginLeft="10px"
                    fontSize="16px"
                    lineHeight="28px"
                    wordBreak="break-word"
                  >
                    {fullName}
                    <br />
                    {formattedDate(item.created_at)}
                  </Text>
                </Stack>

                <Stack
                  display="flex"
                  justifyContent="center"
                  direction="row"
                >
                  <Button
                    background={
                      hideText
                        ? COLORS.darkGrayGradientBotToTop
                        : COLORS.lightGoldGradientLeftToRight
                    }
                    border="solid 1px rgb(119, 119, 119)"
                    padding="2px 0"
                    fontSize="19px"
                    width="95%"
                    borderRadius="5px"
                    align="center"
                    letterSpacing=".15px"
                    fontWeight="400"
                    _hover={{
                      background: ''
                    }}
                    _active={{
                      background: ''
                    }}
                    onClick={() => {
                      if (hideText) {
                        setHideText(0)
                      } else {
                        setHideText(1)
                      }
                    }}
                  >
                    {item.title}
                  </Button>
                </Stack>

                <Stack
                  direction="row"
                  padding="2.5px 0px"
                  justifyContent="center"
                  margin="-2.5px 0"
                  display={hideText ? 'none' : 'flex'}
                >
                  {
                    item.affinities.length
                      ? item.affinities.map((e) => (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize="11px"
                        >
                          {e.title}
                        </Box>
                      ))
                      : (
                        <Box
                          color="#F8F8FF"
                          border="solid 1px black"
                          borderRadius="5px"
                          bg={COLORS.gold}
                          p="0 2px"
                          data-name="blog-affinity"
                          fontSize="11px"
                        >
                          No Affinities
                        </Box>
                      )
                  }
                </Stack>

                <Text
                  display={hideText ? 'none' : 'block'}
                  whiteSpace="pre-wrap"
                >
                  {item.body}
                </Text>

                <Stack
                  direction="row"
                  borderTop="solid 1px black"
                  width="calc(100% + 20px)"
                  padding="3.5px 0px"
                  justifyContent="space-around"
                  marginBottom="-10px"
                  marginLeft="-10px"
                >
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    onClick={() => likeThisPost(item.id)}
                  >
                    <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                    <Text>{item.likes}</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <CommentIcon />
                    <Text>{item.comments}</Text>
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <RepostIcon />
                  </Stack>

                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <ShareIcon />
                  </Stack>
                </Stack>
              </Stack>
            )
          })
          : (
            <Stack
              alignItems="center"
              width="calc(100% + 110px)"
              marginLeft="-55.5px"
            >
              <Text>Click the "Let's share something" button to create a post</Text>
            </Stack>
          )
      }
    </Stack>
  )
}
