import { useDispatch } from 'react-redux'
import {
  Image,
  Stack,
  Text
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import { likeComment } from 'store'
import {
  LikeIcon,
  CommentIcon,
  RepostIcon,
  ShareIcon
} from 'assets/icons'
import { LoadingSpinier } from 'common/Loading'
import { PENDING } from 'utils/constants'
import { CreateComment } from 'components/Notifications/CommunityBlogs/CreateComment'

const formattedDate = (origDate) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const date = new Date(origDate)

  return date.toLocaleDateString('en-US', options)
}

export const CommentsForPost = ({
  comments,
  getCommentsStatus,
  blogParent
}) => {
  const dispatch = useDispatch()

  if (getCommentsStatus === PENDING && !comments.length) {
    return (
      <Stack marginTop="100px">
        <LoadingSpinier />
      </Stack>
    )
  }

  return (
    <Stack marginTop="2%">
      <Stack
        display="flex"
        justifyContent="center"
        direction="row"
        marginBottom="2%"
      >
        <Text
          background={COLORS.lightGoldGradientLeftToRight}
          padding="2px 0"
          fontSize="19px"
          width="85%"
          borderRadius="5px"
          align="center"
          letterSpacing=".15px"
          fontWeight="400"
        >
          {blogParent[0].title}
        </Text>
      </Stack>

      <CreateComment
        parentBlog={blogParent[0]}
        type="post"
      />

      {
        comments && comments.length
          ? comments.map((item) => (
            <Stack
              backgroundColor="rgb(229, 229, 229)"
              width="96%"
              borderRadius="3px"
              border="solid 1px black"
              padding="10px"
              display="flex"
              justifyContent="flex-start"
              margin="0 2%"
            >
              <Stack
                alignItems="center"
                display="inline"
                width="auto"
                justifyContent="flex-start"
                marginLeft="30px"
              >
                <Image
                  boxSize="55px"
                  position="relative"
                  objectFit="cover"
                  src={item.profile_picture || ImagesPng.DefaultImage}
                  alt="User Image"
                  borderRadius="full"
                  display="inline-flex"
                  verticalAlign="bottom"
                />
                <Text
                  display="inline-flex"
                  marginLeft="10px"
                  fontSize="16px"
                  lineHeight="28px"
                  wordBreak="break-word"
                >
                  {item.full_name}
                  <br />
                  {formattedDate(item.created_at)}
                </Text>
              </Stack>

              <Text
                whiteSpace="pre-wrap"
              >
                {item.body}
              </Text>

              <Stack
                direction="row"
                borderTop="solid 1px black"
                width="calc(100% + 20px)"
                padding="3.5px 0px"
                justifyContent="space-around"
                marginBottom="-10px"
                marginLeft="-10px"
              >
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => dispatch(likeComment(item.comment_id))}
                >
                  <LikeIcon fill={item.likedByMe ? COLORS.gold : 'none'} />
                  <Text>{item.likes}</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <CommentIcon />
                  <Text>{item.comments}</Text>
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <RepostIcon />
                </Stack>

                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <ShareIcon />
                </Stack>
              </Stack>
            </Stack>
          ))
          : (
            <Stack
              alignItems="center"
              width="calc(100% + 110px)"
              marginLeft="-55.5px"
            >
              <Text>There are no comments</Text>
            </Stack>
          )
      }
    </Stack>
  )
}
