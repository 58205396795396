import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Image,
  Input,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getNotificationMessage,
  postPosts,
  putPosts
} from 'store'
import { PENDING, REJECTED, SUCCEEDED } from 'utils/constants'
import { CloseIcon } from 'assets/icons'
import { Affinities } from 'components/UserSection/ProfileBlog/Affinities'
import { LoadingSpinier } from 'common/Loading'

export const ProfileBlogForm = ({
  profileId,
  profilePicture,
  editPostData,
  setEditPostData,
  postPostsStatus,
  putPostsStatus,
  affinityList
}) => {
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [archiveText, setArchiveText] = useState('Archive')
  const [planText, setPlanText] = useState('Plan')
  const [affinitiesArray, setAffinitiesArray] = useState([])
  const [chooseAffi, setChooseAffi] = useState(false)
  const parentStackRef = useRef(null)
  const formTitleRef = useRef(null)
  const formBodyRef = useRef(null)

  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const closeForm = () => {
    setTitle('')
    setBody('')
    setEditMode(false)
    setChooseAffi(false)
    setAffinitiesArray([])
    setEditPostData({})
  }

  const submitForm = () => {
    if (title.trim() === '' || body.trim() === '') {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Title and body field is required',
          isEditProfile: true
        })
      )
    } else {
      if (Object.keys(editPostData).length) {
        const data = {
          id: editPostData.profile_id,
          post_id: editPostData.id,
          title,
          body
        }

        if (affinitiesArray.length) {
          data.affinities = [(affinitiesArray.find(() => true)).id]
        }

        dispatch(putPosts(data))
      } else {
        const data = {
          id: profileId,
          title,
          body
        }

        if (affinitiesArray.length) {
          data.affinities = [(affinitiesArray.find(() => true)).id]
        }

        dispatch(postPosts(data))
      }

      console.log()
    }
  }

  const handleResize = () => {
    if (formBodyRef.current) {
      const additionalPadding = affinitiesArray.length ? 25 : 0

      formBodyRef.current.style.height = '5px'
      formBodyRef.current.style.height = `
        ${formBodyRef.current.scrollHeight + additionalPadding}px
      `
    }
  }

  const focusTitleRef = () => {
    if (editMode && formTitleRef.current) {
      formTitleRef.current.blur()
      formTitleRef.current.focus()
    }
  }

  useEffect(() => {
    handleResize()
  }, [body, affinitiesArray])

  useEffect(() => {
    if (editMode && formTitleRef.current) {
      focusTitleRef()
    }
  }, [editMode])

  useEffect(() => {
    if (Object.keys(editPostData).length !== 0) {
      setEditMode(true)
      setTitle(editPostData.title)
      setBody(editPostData.body)
      setAffinitiesArray(editPostData.affinities)
      focusTitleRef()
    }
  }, [editPostData])

  useEffect(() => {
    if (putPostsStatus === SUCCEEDED) {
      parentStackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      closeForm()
    }

    if (putPostsStatus === REJECTED) {
      parentStackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [putPostsStatus])

  useEffect(() => {
    if (postPostsStatus === SUCCEEDED) {
      parentStackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      closeForm()
    }

    if (postPostsStatus === REJECTED) {
      parentStackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [postPostsStatus])

  return (
    <Stack
      ref={parentStackRef}
      background={COLORS.grayGradientTopToBot}
      width="calc(100% + 112px)"
      marginLeft="-56px"
      marginRight="auto"
      marginTop="15px"
      borderRadius="3px"
      padding="8px"
    >
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <Image
          boxSize="45px"
          objectFit="cover"
          src={profilePicture || ImagesPng.DefaultImage}
          alt="User Image"
          borderRadius="full"
          display="inline-flex"
        />
        {
          editMode
            ? (
              <Input
                ref={formTitleRef}
                background={COLORS.lighterGray}
                color="rgb(100, 100, 100)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                placeholder="Title here"
                name="blog-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            )
            : (
              <Text
                background={COLORS.lighterGray}
                color="rgb(150, 150, 150)"
                padding="8px"
                borderRadius={3}
                flex="1"
                maxWidth="calc(100% - 51px)"
                height="35px"
                display="inline-flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => setEditMode(true)}
                _hover={{
                  background: 'rgb(220, 220, 220)',
                  border: 'solid 1px black'
                }}
                transition="0.3s"
              >
                Let's share something
              </Text>
            )
        }
      </Stack>
      {
        editMode
          ? (
            <>
              <Stack position="relative">
                <Textarea
                  ref={formBodyRef}
                  background={COLORS.lighterGray}
                  placeholder="Body here"
                  width="100%"
                  height="auto"
                  minHeight="5em"
                  overflow="hidden"
                  resize="none"
                  name="blog-body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />
                <Stack
                  rowGap="10px"
                  spacing="unset"
                  wrap="wrap"
                  direction="row"
                  display={affinitiesArray.length > 0 ? 'flex' : 'none'}
                  position="absolute"
                  left="5px"
                  bottom="5px"
                  zIndex="5"
                >
                  {affinitiesArray.map((item) => (
                    <Stack
                      direction="row"
                      width="max-content"
                      alignItems="center"
                      color="#F8F8FF"
                      border="solid 1px black"
                      borderRadius="5px"
                      bg={COLORS.gold}
                      p="0 2px"
                      data-name="blog-affinity"
                      fontSize="11px"
                      cursor="pointer"
                      pointerEvents={chooseAffi ? 'auto' : 'none'}
                      onClick={() => setAffinitiesArray([])}
                    >
                      <Text data-name="affinity-title">{item.title}</Text>
                      <Stack marginLeft="-8px" marginRight="2px" width="11px" height="11px">
                        <CloseIcon fill="#F8F8FF" />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                padding="2.5px 0px"
                justifyContent="space-between"
                margin="-2.5px 0"
                fontSize="13px"
                overflowX="auto"
              >
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-cancel"
                  cursor="pointer"
                  onClick={() => closeForm()}
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                  pointerEvents={putPostsStatus !== PENDING && postPostsStatus !== PENDING ? 'auto' : 'none'}
                >
                  Cancel
                </Box>
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-media"
                  cursor="pointer"
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                >
                  Media
                </Box>
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-affinity"
                  cursor="pointer"
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                  onClick={() => {
                    if (chooseAffi) {
                      setChooseAffi(false)
                    } else {
                      setChooseAffi(true)
                    }
                  }}
                >
                  Affinity
                </Box>
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-archive"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                  onClick={(e) => {
                    setArchiveText('Coming soon')
                    setTimeout(() => { setArchiveText('Archive') }, 3000)
                  }}
                >
                  { archiveText }
                </Box>
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-plan"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                  onClick={() => {
                    setPlanText('Coming soon')
                    setTimeout(() => { setPlanText('Plan') }, 3000)
                  }}
                >
                  { planText }
                </Box>
                <Box
                  borderRadius="full"
                  bg={COLORS.gold}
                  p="6px 10px"
                  data-name="blog-post"
                  cursor="pointer"
                  _hover={{ background: COLORS.gold_hover }}
                  transition="0.3s"
                  pointerEvents={putPostsStatus !== PENDING && postPostsStatus !== PENDING ? 'auto' : 'none'}
                  onClick={() => submitForm()}
                >
                  {
                    putPostsStatus === PENDING
                    || postPostsStatus === PENDING
                      ? (
                        <Stack>
                          <LoadingSpinier
                            width="10px"
                            height="10px"
                            marginTop="23px"
                          />
                        </Stack>
                      ) : 'Post'
                  }
                </Box>
              </Stack>
              {
                chooseAffi
                  ? (
                    <Stack>
                      <Affinities
                        affinitiesArray={affinitiesArray}
                        setAffinitiesArray={setAffinitiesArray}
                        affinityList={affinityList}
                      />
                    </Stack>
                  ) : ''
              }
            </>
          )
          : ''
      }
    </Stack>
  )
}
