import { useEffect, useRef, useState } from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Box,
  Image,
  Stack,
  Textarea
} from '@chakra-ui/react'
import { COLORS } from 'utils/styles'
import { ImagesPng } from 'assets/images'
import {
  getNotificationMessage,
  postComments,
  usersSelectors
} from 'store'
import { PENDING, REJECTED, SUCCEEDED } from 'utils/constants'
import { LoadingSpinier } from 'common/Loading'

export const CreateComment = ({
  parentBlog,
  type,
  putPostsStatus
}) => {
  const dispatch = useDispatch()
  const formBodyRef = useRef(null)
  const {
    activeProfile,
    postCommentsStatus
  } = useSelector(usersSelectors)
  const parentStackRef = useRef(null)

  const [body, setBody] = useState('')

  const closeForm = () => {
    setBody('')
  }

  const submitForm = () => {
    if (body.trim() === '') {
      dispatch(
        getNotificationMessage({
          type: REJECTED,
          message: 'Body field is required',
          isEditProfile: true
        })
      )
    } else {
      let data = {}

      if (type === 'post') {
        data = {
          id: activeProfile.id,
          type,
          post_id: parentBlog.id,
          body
        }
      } else if (type === 'comment') {
        data = {
          id: activeProfile.id,
          type,
          comment_id: parentBlog.id,
          body
        }
      }

      dispatch(postComments(data))
    }
  }

  const handleResize = () => {
    if (formBodyRef.current) {
      formBodyRef.current.style.height = '5px'
      formBodyRef.current.style.height = `
        ${formBodyRef.current.scrollHeight}px
      `
    }
  }

  useEffect(() => {
    handleResize()
  }, [body])

  useEffect(() => {
    if (postCommentsStatus === SUCCEEDED) {
      closeForm()
    }

    if (postCommentsStatus === REJECTED) {
      parentStackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [postCommentsStatus])

  return (
    <Stack
      ref={parentStackRef}
      background={COLORS.grayGradientTopToBot}
      width="96%"
      margin="0 2% 1% 2%"
      border="solid 1px black"
      borderRadius="3px"
      padding="8px"
    >
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <Image
          boxSize="45px"
          objectFit="cover"
          src={activeProfile.profile_picture || ImagesPng.DefaultImage}
          alt="User Image"
          borderRadius="full"
          display="inline-flex"
        />

        <Textarea
          ref={formBodyRef}
          background={COLORS.lighterGray}
          placeholder="Add your comment here"
          width="100%"
          height="auto"
          minHeight="5em"
          overflow="hidden"
          resize="none"
          name="blog-body"
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </Stack>
      <Stack
        direction="row"
        padding="2.5px 0px"
        justifyContent="flex-end"
        margin="-2.5px 0"
        fontSize="13px"
        overflowX="auto"
      >
        <Box
          borderRadius="full"
          bg={COLORS.gold}
          p="6px 10px"
          data-name="blog-cancel"
          cursor="pointer"
          onClick={() => closeForm()}
          _hover={{ background: COLORS.gold_hover }}
          transition="0.3s"
          display={body.length ? 'block' : 'none'}
          pointerEvents={putPostsStatus !== PENDING && postCommentsStatus !== PENDING ? 'auto' : 'none'}
        >
          Cancel
        </Box>
        <Box
          borderRadius="full"
          bg={COLORS.gold}
          p="6px 10px"
          data-name="blog-post"
          cursor="pointer"
          _hover={{ background: COLORS.gold_hover }}
          transition="0.3s"
          pointerEvents={putPostsStatus !== PENDING && postCommentsStatus !== PENDING ? 'auto' : 'none'}
          onClick={() => submitForm()}
        >
          {
            putPostsStatus === PENDING
            || postCommentsStatus === PENDING
              ? (
                <Stack>
                  <LoadingSpinier
                    width="10px"
                    height="10px"
                    marginTop="23px"
                  />
                </Stack>
              ) : 'Post'
          }
        </Box>
      </Stack>
    </Stack>
  )
}
