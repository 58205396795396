import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@chakra-ui/react'
import { getPosts, handleModalTitle, usersSelectors } from 'store'
import { UserInformation } from 'components/UserSection/ProfileBlog/UserInformation'
import { ProfileBlogForm } from 'components/UserSection/ProfileBlog/ProfileBlogForm'
import { BlogList } from 'components/UserSection/ProfileBlog/BlogList'
import { VERIFY_PAGE } from 'utils/constants'

export const ProfileBlog = ({
  setUserSectionPage,
  selectedUser,
  affinityList
}) => {
  const dispatch = useDispatch()
  const [reload, setReload] = useState(0)
  const [editPostData, setEditPostData] = useState({})

  const {
    posts,
    getPostsStatus,
    postPostsStatus,
    putPostsStatus
  } = useSelector(usersSelectors)

  const {
    id,
    full_name,
    profile_picture,
    banner,
    last_accessed,
    personal_quote
  } = selectedUser

  useEffect(() => {
    dispatch(getPosts({
      type: 'profile',
      id
    }))

    setReload(Math.random())
  }, [selectedUser])

  useEffect(() => {
    dispatch(handleModalTitle(VERIFY_PAGE.ProfileBlog))
  }, [])

  return (
    <Stack>
      {reload && (
        <Stack direction="column">
          <UserInformation
            profilePicture={profile_picture}
            fullName={full_name}
            lastAccessed={last_accessed}
            setUserSectionPage={setUserSectionPage}
            quote={personal_quote}
            coverPicture={banner}
          />
          <ProfileBlogForm
            profileId={id}
            profilePicture={profile_picture}
            editPostData={editPostData}
            setEditPostData={setEditPostData}
            postPostsStatus={postPostsStatus}
            putPostsStatus={putPostsStatus}
            affinityList={affinityList}
          />
          <BlogList
            profilePicture={profile_picture}
            fullName={full_name}
            posts={posts}
            setEditPostData={setEditPostData}
            getPostsStatus={getPostsStatus}
          />
        </Stack>
      )}
    </Stack>
  )
}
